import React from "react";
import { LatLngTuple } from "leaflet";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";

const KUNZLER_COORDINATE: LatLngTuple = [-29.9858388, -51.1649187];

const LOGO = require("./img/logo192.png");

export function ContactPage() {
  return (
    <>
      <div className="row" style={{ height: "100%" }}>
        <div className="col-lg-6 d-flex flex-column justify-content-around">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <p
                className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                style={{ paddingBottom: "1rem" }}
              >
                Fale conosco
              </p>
              <p>(51) 3061-4488</p>
              <p>contato@kunzlermaquinas.com.br</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="card border-0 shadow-sm"
                style={{ marginBottom: "1rem" }}
              >
                <div className="card-body d-flex flex-column align-items-center">
                  <p
                    className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                    style={{ paddingBottom: "1rem" }}
                  >
                    Máquinas
                  </p>
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999701857"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99970-1857 <i className="fab fa-whatsapp" />
                  </a>
                  <p>comercial@kunzlermaquinas.com.br</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card border-0 shadow-sm"
                style={{ marginBottom: "1rem" }}
              >
                <div className="card-body d-flex flex-column align-items-center">
                  <p
                    className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                    style={{ paddingBottom: "1rem" }}
                  >
                    Instagram
                  </p>
                  <a
                    href="https://www.instagram.com/kunzlermaquinas/"
                    style={{ textDecoration: "none", color: "#8a3ab9" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    @kunzlermaquinas <i className="fab fa-instagram" />
                  </a>
                  <p style={{ color: "transparent", cursor: "none" }}>.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div
              className="card border-0 shadow-sm"
              style={{ marginBottom: "1rem" }}
            >
              <div className="card-body d-flex flex-column align-items-center">
                <p
                  className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                  style={{ paddingBottom: "1rem" }}
                >
                  Peças
                </p>
                <p>
                  cyro@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999801999"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99980-1999 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  peças1@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999700763"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99970-0763 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  peças2@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551995950654"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99595-0654 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  peças3@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999802293"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99980-2293 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  peças4@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999701184"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99970-1184 <i className="fab fa-whatsapp" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div
              className="card border-0 shadow-sm"
              style={{ marginBottom: "1rem" }}
            >
              <div className="card-body d-flex flex-column align-items-center">
                <p
                  className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                  style={{ paddingBottom: "1rem" }}
                >
                  Serviços
                </p>
                <p>
                  daniel@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551998891028"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99889-1028 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  leandro@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999802009"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99980-2009 <i className="fab fa-whatsapp" />
                  </a>
                </p>
                <p>
                  rene@kunzlermaquinas.com.br{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5551999802293"
                    style={{ textDecoration: "none", color: "#1ad03f" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (51) 99970-0487 <i className="fab fa-whatsapp" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <MapContainer
            center={KUNZLER_COORDINATE}
            style={{ height: 500 }}
            zoom={15}
            scrollWheelZoom={false}
            dragging={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={KUNZLER_COORDINATE}>
              <Popup>
                <img src={LOGO} alt="" style={{ width: "100%" }} />
              </Popup>
            </Marker>
          </MapContainer>
          <div className="card border-0 shadow-sm" style={{ margin: "1rem 0" }}>
            <div className="card-body d-flex flex-column align-items-center">
              <p
                className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                style={{ paddingBottom: "1rem" }}
              >
                Visite-nos
              </p>
              <p>
                Av. das Indústrias, 865 - São João, Porto Alegre - RS,
                90200-290, Brazil
              </p>
            </div>
          </div>
          <div className="card border-0 shadow-sm">
            <div className="card-body d-flex flex-column align-items-center">
              <p
                className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                style={{ paddingBottom: "1rem" }}
              >
                Horário de Funcionamento
              </p>
              <p>Segunda à Sexta: das 8h até às 18h15 </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
