import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { MACHINES } from "./machines";

export function MachinePage() {
  const { id } = useParams<any>();
  const navigate = useNavigate();

  const machineId = id === undefined ? 1 : +id;

  const machine = MACHINES.find((m) => m.id === machineId);

  if (machine === undefined) {
    return <Navigate replace to={"/"} />;
  }

  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: "0.5rem" }}
      >
        <h2>{machine.name}</h2>
        <button
          className="btn btn-primary"
          style={{ backgroundColor: "#ffc03a", borderColor: "#ffc03a" }}
          onClick={() => navigate(`/novas?type=${machine.type}`)}
        >
          Voltar
        </button>
      </div>
      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-top">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <img
                src={machine.img}
                alt=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </div>
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              {machine.specs.map((p) => (
                <>
                  <p className="fw-bold" style={{ margin: 0, width: "100%" }}>
                    {p.key}
                  </p>
                  <p style={{ margin: 0, textAlign: "right", width: "100%" }}>
                    {p.value}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-top">
          {machine.props.map((p) => (
            <div
              className="card border-0 shadow-sm"
              style={{ marginBottom: "1rem" }}
            >
              <div className="card-body d-flex flex-column align-items-center">
                <p
                  className="h5 fw-bold ml-3 ml-lg-4 mb-0"
                  style={{ paddingBottom: "1rem", width: "100%" }}
                >
                  {p.title}
                </p>
                <p style={{ textAlign: "justify" }}>{p.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
