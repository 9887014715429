type Properties = {
  title: string;
  description: string;
};

type Specs = {
  key: string;
  value: string;
};

export type NewMachine = {
  id: number;
  name: string;
  img: string;
  specs: Specs[];
  props: Properties[];
  type: "bulldozer" | "wheelLoader";
};

export const MACHINES: NewMachine[] = [
  {
    id: 1,
    name: "R150LC-9",
    img: require("./img/R150LC-9.jpg"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 4.5 TIER III" },
      { key: "Potência bruta SAE J1995", value: "130 HP / 2.200 rpm" },
      { key: "Capacidade da caçamba escavação", value: "0.71 m³" },
      { key: "Peso operacional", value: "14.210 kg" },
      { key: "Profundidade máxima de escavação", value: "5.500 mm" },
      { key: "Altura máxima de escavação", value: "8.500 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "102,0 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MOTOR CUMMINS QSB4.5",
        description:
          "O motor Cummins QSB4.5 combina controles de eletrônica avançada e um sistema de autodiagnostico para elevado desempenho. O sistema de injeção common rail resulta em uma combustão mais eficiente gerando maior potência, e redução no consumo de combustível. O motor Cummins QSB 4.5 está em conformidade com as normas atuais referentes à emissões EPA TIER III, MAR-I e EU Stage III-A.",
      },
      {
        title: "SISTEMA HIDRÁULICO EFICIENTE",
        description:
          "Para atingir o melhor desempenho, a Hyundai redesenhou seu sistema hidráulico proporcionando ao operador comandos precisos e controles aprimorados. Melhorias no controle do sistema hidráulico reduzem o fluxo quando estes não estão sendo utilizados para minimizar o consumo de combustível. Os recursos de regeneração de energia nos movimentos de braço e lança geram a melhoria nos sistemas de controles hidráulicos e a seleção automática de prioridade de movimento entre lança e sistema de giro que garantem desempenho ideal em qualquer aplicação.",
      },
      {
        title: "SISTEMA AVANÇADO CAPO EXCLUSIVO HYUNDAI",
        description:
          "Otimização de potência entre o sistema hidráulico e o motor diesel. O operador pode definir suas próprias preferências para a prioridade da lança ou giro, seleção do modo de potência e ferramentas de trabalho opcionais com o toque de um botão. O sistema CAPO também fornece recursos completos de autodiagnostico e medidores digitais para informações importantes, como temperatura do óleo hidráulico, temperatura da água e nível de combustível.",
      },
      {
        title: "CABINE SÉRIE 9",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 2,
    name: "R180LC-9",
    img: require("./img/R180LC-9.jpg"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 4.5 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "130 HP (97 KW) / 2.200 rpm" },
      { key: "Capacidade da caçamba escavação", value: "0.91 m³" },
      { key: "Peso operacional", value: "18.050 kg" },
      { key: "Profundidade máxima de escavação", value: "6.060 mm" },
      { key: "Altura máxima de escavação", value: "8.840 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "123,6 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor CUMMINS QSB 4.5 Tier III combina controles de eletrônica avançada e um sistema de auto diagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução do consumo de combustível. O motor CUMMINS QSB 4.5 Tier III está em conformidade com as normas atuais referentes a emissões, incluindo EPA Tier III e EU Fase III-A.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "Duas potentes bombas hidráulicas alinhadas de alta confiabilidade, com pistões axiais e vazão variável, equipadas com novo bloco compacto de quatro válvulas solenoides, uma de retenção, uma no acumulador, uma EPPR e uma do filtro piloto.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 3,
    name: "R200-9",
    img: require("./img/R200-9.jpg"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 4.5 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "130 HP (97 KW) / 2.200 rpm" },
      { key: "Capacidade da caçamba escavação", value: "1.00 m³" },
      { key: "Peso operacional", value: "19.100 kg" },
      { key: "Profundidade máxima de escavação", value: "6.060 mm" },
      { key: "Altura máxima de escavação", value: "8.840 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "123,6 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor Cummins QSB4.5 combina controles de Eletrônica avançada e um sistema de autodiagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB4,5 está em conformidade com as normas atuais referentes à emissões, incluindo EPA Tier III e EU Stage III-A.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "A Hyundai redesenhou o sistema hidráulico das escavadeiras 9 para proporcionar ao operador um toque suave e maior capacidade de controle dos movimentos com o menor esforço. Através do exclusivo sistema de gerenciamento eletrônico, é possível adequar a potência necessária para os mais diversos serviços. Economiza-se combustível nos trabalhos mais leves e obtém-se alta performance em trabalhos mais pesados. Ajuste fino para otimizar a velocidade entre o movimento da lança e do giro.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 4,
    name: "R220LC-9",
    img: require("./img/R220LC-9.png"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "155 HP (116KW) / 2.000 rpm" },
      { key: "Capacidade da caçamba escavação", value: "1.20 m³" },
      { key: "Peso operacional", value: "22.250 kg" },
      { key: "Profundidade máxima de escavação", value: "6.730mm" },
      { key: "Altura máxima de escavação", value: "9.600 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "152,0 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor Cummins QSB6.7 combina controles de Eletrônica avançada e um sistema de autodiagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB6.7 está em conformidade com as normas atuais referentes à emissões, incluindo EPA Tier III e EU Stage III-A.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "A Hyundai redesenhou o sistema hidráulico das escavadeiras 9 para proporcionar ao operador um toque suave e maior capacidade de controle dos movimentos com o menor esforço. Através do exclusivo sistema de gerenciamento eletrônico, é possível adequar a potência necessária para os mais diversos serviços. Economiza-se combustível nos trabalhos mais leves e obtém-se alta performance em trabalhos mais pesados. Ajuste fino para otimizar a velocidade entre o movimento da lança e do giro.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 5,
    name: "R225L VS",
    img: require("./img/R225L VS.png"),
    specs: [
      { key: "Motor", value: "MOTOR CUMMINS QSB7 MAR-I/TIER 3" },
      { key: "Potência bruta SAE J1995", value: "167 HP (125 KW) / 1.900rpm" },
      { key: "Capacidade da caçamba escavação", value: "1.20 m³" },
      { key: "Peso operacional", value: "22.920 kg" },
      { key: "Profundidade máxima de escavação", value: "6.730mm" },
      { key: "Altura máxima de escavação", value: "9.600 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "133,3 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor Cummins QSB6.7 combina controles de Eletrônica avançada e um sistema de autodiagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB6.7 está em conformidade com as normas atuais referentes à emissões, incluindo Proconve MAR-I, EPA Tier 3 e EU Stage III-A.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "A Hyundai redesenhou o sistema hidráulico das escavadeiras 9 para proporcionar ao operador um toque suave e maior capacidade de controle dos movimentos com o menor esforço. Através do exclusivo sistema de gerenciamento eletrônico, é possível adequar a potência necessária para os mais diversos serviços. Economiza-se combustível nos trabalhos mais leves e obtém-se alta performance em trabalhos mais pesados. Ajuste fino para otimizar a velocidade entre o movimento da lança e do giro.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 6,
    name: "R220LC-9 Long Reach",
    img: require("./img/R220LC-9 Long Reach.jpg"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "155 HP (116KW) / 2.000 rpm" },
      { key: "Capacidade da caçamba escavação", value: "0.52 m³" },
      { key: "Peso operacional", value: "24.360 kg" },
      { key: "Profundidade máxima de escavação", value: "11.760 mm" },
      { key: "Altura máxima de escavação", value: "12.550 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "83,4 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor Cummins QSB6.7 combina controles de Eletrônica avançada e um sistema de autodiagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB6.7 está em conformidade com as normas atuais referentes à emissões, incluindo EPA Tier III e EU Stage III-A.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "A Hyundai redesenhou o sistema hidráulico das escavadeiras 9 para proporcionar ao operador um toque suave e maior capacidade de controle dos movimentos com o menor esforço. Através do exclusivo sistema de gerenciamento eletrônico, é possível adequar a potência necessária para os mais diversos serviços. Economiza-se combustível nos trabalhos mais leves e obtém-se alta performance em trabalhos mais pesados. Ajuste fino para otimizar a velocidade entre o movimento da lança e do giro.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 7,
    name: "R260LC-9",
    img: require("./img/R260LC-9.jpg"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "190 HP (142KW) / 2.200 rpm" },
      { key: "Capacidade da caçamba escavação", value: "1,46m³" },
      { key: "Peso operacional", value: "25.200 kg" },
      { key: "Profundidade máxima de escavação", value: "6.450 mm" },
      { key: "Altura máxima de escavação", value: "9.460 mm" },
      { key: "Força de escavação da caçamba [ISO]", value: "178,5 KN" },
    ],
    type: "bulldozer",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "O motor QSB6.7 CUMMINS, em conformidade com as normas de emissões atuais EPA Tier 3 e EU Stage III-A, combina avançado controle eletrônico e um sistema de autodiagnostico que orienta o operador a tomar as decisões em campo. A combinação do sistema de Common Rail de alta pressão com uma avançada tecnologia de combustão nos cilindros resulta em maior potência e melhor resposta transiente, com menor consumo de combustível.",
      },
      {
        title: "AVANÇADO SISTEMA HIDRÁULICO",
        description:
          "A Hyundai redesenhou o sistema hidráulico das escavadeiras 9 para proporcionar ao operador um toque suave e maior capacidade de controle dos movimentos com o menor esforço. Através do exclusivo sistema de gerenciamento eletrônico, é possível adequar a potência necessária para os mais diversos serviços. Economiza-se combustível nos trabalhos mais leves e obtém-se alta performance em trabalhos mais pesados. Ajuste fino para otimizar a velocidade entre o movimento da lança e do giro.",
      },
      {
        title: "CAPO – SISTEMA COMPUTADORIZADO AUMENTO DE POTÊNCIA",
        description:
          "A potência do motor e potência hidráulica, em uníssono, através do avançado e exclusivo CAPO (Sistema computadorizado de aumento de potência), permitem ao operador definir suas próprias preferências de movimentação da lança. Todos os recursos de melhoria de desempenho estão ao alcance das mãos do operador, basta acionar um botão. O sistema CAPO também oferece recursos como o autodiagnostico e medidores digitais para informações importantes como temperatura do óleo hidráulico, temperatura da água e nível de combustível. Este sistema faz a interface com múltiplos sensores do sistema hidráulico, bem como o fluxo hidráulico.",
      },
      {
        title: "CABINE",
        description:
          "A estrutura das cabines dos equipamentos Hyundai série 9 são resistentes, devido a sua estrutura em aço reforçado. Apesar de sua maior resistência, a tubulação estrutural da cabine é fina, o que proporciona maior visibilidade frontal e lateral do campo de trabalho.",
      },
    ],
  },
  {
    id: 8,
    name: "HL745-9",
    img: require("./img/HL745-9.png"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      { key: "Potência bruta SAE J1995", value: "145 HP (107 KW) / 2.100 rpm" },
      { key: "Capacidade da caçamba escavação", value: "2,1 m³" },
      { key: "Peso operacional", value: "12.100 kg" },
      {
        key: "Carga de tombamento, completamente articulada",
        value: "7.890 kg",
      },
      {
        key: "Força de arrancamento",
        value: "111,2 KN",
      },
      {
        key: "Pneu",
        value: "17.5-25 ou 20.5-25 16PR L3",
      },
    ],
    type: "wheelLoader",
    props: [
      {
        title: "MODELO DO MOTOR",
        description:
          "Motor Cummins QSB6.7.O motor Cummins QSB6.7 combina controles de Eletrônica avançada e um sistema de autodiagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB6.7 está em conformidade com as normas atuais referentes à emissões, incluindo EPA Tier III e EU Stage III-A.",
      },
      {
        title: "EIXO ZF DE MAIOR ROBUSTEZ",
        description:
          "Eixo reforçado, que permite a locomoção em todos os tipos de condições de solo, e sistema de freio a disco em banho de óleo, que garante alto desempenho e segurança na frenagem.",
      },
      {
        title: "SISTEMA DE REFRIGERAÇÃO",
        description:
          "O novo design do sistema de radiadores das máquinas série 9 foi projetado para evitar entupimentos. Suas aletas de alumínio passam por testes rigorosos de durabilidade, verificação de choque térmico e impactos para assegurar longa vida útil de seus componentes. Sistema de ar-condicionado com alta performance de refrigeração, tendo como característica a economia de energia e fácil limpeza.",
      },
      {
        title: "MODOS VARIÁVEIS DE OPERAÇÃO",
        description:
          "As Carregadeiras Hyundai série 9 são projetadas a fim de permitir a personalização durante a mudança de transmissão da embreagem de corte de acordo com as condições de trabalho e a preferência do operador. As chaves do tipo rotativo permitem a fácil adaptação do modo de deslocamento do poder de transmissão e embreagem no modo cut-off. Além disso, se equipado com o sistema de controle de passeio opcional, o operador tem a opção de desligar o interruptor de ultrapassagem. O sistema de controle de passeio tem uma absorção de choque que amortece o crescimento, melhora o conforto do operador e reduz a perda de material. A versatilidade dos modos de operação da série 9 contribui para a melhoria da produtividade, maior conforto do operador e redução do consumo de combustível.",
      },
      {
        title: "CABINE",
        description:
          "As cabines das pás carregadeiras Hyundai Série 9 foram projetadas com maior espaço interno, amplo campo de visão e prioriza o conforto do operador. O vidro dianteiro é arredondado e 17% maior que o de nossa série anterior. Seu interior é ergonômico e atende às normas de regulamentação de segurança. As Pás carregadeiras Hyundai proporcionam as condições perfeitas para a execução de um trabalho, não importa o quão seja pesado. O seu equipamento vem com avançado sistema de controle de temperatura e desembaçadores frontais e traseiros.",
      },
    ],
  },
  {
    id: 9,
    name: "HL757-9",
    img: require("./img/HL757-9.png"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      {
        key: "Potência bruta SAE J1995",
        value: "173 HP (129 KW) / 2.100 rpm",
      },
      { key: "Capacidade da caçamba escavação", value: "2,7 m³" },
      { key: "Peso operacional", value: "14.200 kg" },
      {
        key: "Carga de tombamento, completamente articulada",
        value: "9.730 kg",
      },
      {
        key: "Força de arrancamento",
        value: "124,7 KN",
      },
      {
        key: "Pneu",
        value: "20.5-25 16PR L3",
      },
    ],
    type: "wheelLoader",
    props: [
      {
        title: "MOTOR",
        description:
          "O motor Cummins QSB 6.7 combina controles de Eletrônica avançada e um sistema de auto diagnóstico com desempenho confiável. A combinação de uma alta pressão comum e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta transiente e redução no consumo de combustível. O Motor Cummins QSB6.7 está em conformidade com as normas atuais referentes à emissões, incluindo EPA Tier III e EU Stage III-A.",
      },
      {
        title: "EIXO ZF DE MAIOR ROBUSTEZ",
        description:
          "Eixo reforçado, que permite a locomoção em todos os tipos de condições de solo, e sistema de freio a disco em banho de óleo, que garante alto desempenho e segurança na frenagem.",
      },
      {
        title: "SISTEMA DE REFRIGERAÇÃO",
        description:
          "O novo design do sistema de radiadores das máquinas série 9 foi projetado para evitar entupimentos. Suas aletas de alumínio passam por testes rigorosos de durabilidade, verificação de choque térmico e impactos para assegurar longa vida útil de seus componentes. Sistema de ar-condicionado com alta performance de refrigeração, tendo como característica a economia de energia e fácil limpeza.",
      },
      {
        title: "MODOS VARIÁVEIS DE OPERAÇÃO",
        description:
          "As Carregadeiras Hyundai série 9 são projetadas a fim de permitir a personalização durante a mudança de transmissão da embreagem de corte de acordo com as condições de trabalho e a preferência do operador. As chaves do tipo rotativo permitem a fácil adaptação do modo de deslocamento do poder de transmissão e embreagem no modo cut-off. Além disso, se equipado com o sistema de controle de passeio opcional, o operador tem a opção de desligar o interruptor de ultrapassagem. O sistema de controle de passeio tem uma absorção de choque que amortece o crescimento, melhora o conforto do operador e reduz a perda de material. A versatilidade dos modos de operação da série 9 contribui para a melhoria da produtividade, maior conforto do operador e redução do consumo de combustível.",
      },
      {
        title: "CABINE",
        description:
          "As cabines das pás carregadeiras Hyundai Série 9 foram projetadas com maior espaço interno, amplo campo de visão e prioriza o conforto do operador. O vidro dianteiro é arredondado e 17% maior que o de nossa série anterior. Seu interior é ergonômico e atende às normas de regulamentação de segurança. As Pás carregadeiras Hyundai proporcionam as condições perfeitas para a execução de um trabalho, não importa o quão seja pesado. O seu equipamento vem com avançado sistema de controle de temperatura e desembaçadores frontais e traseiros.",
      },
    ],
  },
  {
    id: 10,
    name: "HL760-9",
    img: require("./img/HL760-9.png"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 6.7 MAR-I / Tier 3" },
      {
        key: "Potência bruta SAE J1995",
        value: "215 HP (160 KW) / 2.100 rpm",
      },
      { key: "Capacidade da caçamba escavação", value: "3,1 m³" },
      { key: "Peso operacional", value: "19.100 kg" },
      {
        key: "Carga de tombamento, completamente articulada",
        value: "12.460 Kg",
      },
      {
        key: "Força de arrancamento",
        value: "156,4 KN",
      },
      {
        key: "Pneu",
        value: "23.5-25 20PR L5",
      },
    ],
    type: "wheelLoader",
    props: [
      {
        title: "MOTOR",
        description:
          "O motor eletrônico Cummins QSB 6.7 teve seu desenvolvimento baseado no grande sucesso da versão mecânica dos motores série B. Com intercooler e turbos que disponibilizam um alto desempenho e sofisticados controles eletrônicos, que resultam em confiabilidade e durabilidade, garantem uma superior operação, característicos dos motores Cummins. O Cummins QSB6.7 atende às normas de emissões Tier III, além de ser equipado com a tecnologia triple power, que disponibiliza três curvas de potência que possibilita o desempenho exigido de um motor de alta potência, mas com menor consumo de combustível.",
      },
      {
        title: "EIXO ZF DE MAIOR ROBUSTEZ",
        description:
          "Eixo reforçado, que permite a locomoção em todos os tipos de condições de solo, e sistema de freio a disco em banho de óleo, que garante alto desempenho e segurança na frenagem.",
      },
      {
        title: "SISTEMA DE REFRIGERAÇÃO",
        description:
          "O novo design do sistema de radiadores das máquinas série 9 foi projetado para evitar entupimentos. Suas aletas de alumínio passam por testes rigorosos de durabilidade, verificação de choque térmico e impactos para assegurar longa vida útil de seus componentes. Sistema de ar-condicionado com alta performance de refrigeração, tendo como característica a economia de energia e fácil limpeza.",
      },
      {
        title: "MODOS VARIÁVEIS DE OPERAÇÃO",
        description:
          "As Carregadeiras Hyundai série 9 são projetadas a fim de permitir a personalização durante a mudança de transmissão da embreagem de corte de acordo com as condições de trabalho e a preferência do operador. As chaves do tipo rotativo permitem a fácil adaptação do modo de deslocamento do poder de transmissão e embreagem no modo cut-off. Além disso, se equipado com o sistema de controle de passeio opcional, o operador tem a opção de desligar o interruptor de ultrapassagem. O sistema de controle de passeio tem uma absorção de choque que amortece o crescimento, melhora o conforto do operador e reduz a perda de material. A versatilidade dos modos de operação da série 9 contribui para a melhoria da produtividade, maior conforto do operador e redução do consumo de combustível.",
      },
      {
        title: "CABINE",
        description:
          "As cabines das pás carregadeiras Hyundai Série 9 foram projetadas com maior espaço interno, amplo campo de visão e prioriza o conforto do operador. O vidro dianteiro é arredondado e 17% maior que o de nossa série anterior. Seu interior é ergonômico e atende às normas de regulamentação de segurança. As Pás carregadeiras Hyundai proporcionam as condições perfeitas para a execução de um trabalho, não importa o quão seja pesado. O seu equipamento vem com avançado sistema de controle de temperatura e desembaçadores frontais e traseiros.",
      },
    ],
  },
  {
    id: 11,
    name: "HL635L",
    img: require("./img/HL635L.png"),
    specs: [
      { key: "Motor", value: "CUMMINS QSB 5.9 MAR-I / Tier 3" },
      {
        key: "Potência bruta SAE J1995",
        value: "130 HP (97 KW) / 2.300 rpm",
      },
      { key: "Capacidade da caçamba escavação", value: "1,7 m³" },
      { key: "Peso operacional", value: "10.300 kg" },
      {
        key: "Carga de tombamento, completamente articulada",
        value: "6.000 kg",
      },
      {
        key: "Força de arrancamento",
        value: "98,06 KN",
      },
      {
        key: "Pneu",
        value: "17.5-25 L3",
      },
    ],
    type: "wheelLoader",
    props: [
      {
        title: "MOTOR",
        description:
          "O motor Cummins QSB5.9 combina gerenciamento eletrônico a um sistema de autodiagnóstico com desempenho confiável. A combinação do sistema common rail de alta pressão e um avançado sistema de combustão nos cilindros resulta em maior potência, melhor resposta e redução no consumo de combustível. O Motor Cummins QSB 5.9 está em conformidade com as normas atuais referentes a emissões – MAR-I, EPA Tier 3 e EU Stage III-A.",
      },
      {
        title: "ECONOMIA E EFICIÊNCIA",
        description:
          "A HL635L Hyundai foi projetada para ser resistente e com mecânica simples para facilitar a manutenção pelos clientes e baixar o custo de manutenção, com isso se tornando a máquina ideal para todo o perfil de cliente.",
      },
      {
        title: "CABINE",
        description:
          "As cabines das pás carregadeiras Hyundai foram projetadas com maior espaço interno, amplo campo de visão e prioriza o conforto do operador. O vidro dianteiro é arredondado e 17% maior que o de nossa série anterior. Seu interior é ergonômico e atende às normas de regulamentação de segurança.",
      },
    ],
  },
];
