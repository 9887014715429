import React from "react";
import { MachineCard } from "../../components/MachineCard";

export type Machine = {
  name: string;
  img: string;
  description: string;
};

const machines: Machine[] = [
  {
    name: "Escavadeira",
    img: require("./img/R140LC-7-2007.jpg"),
    description: "MARCA HYUNDAI, MODELO R140LC-7, ANO 2007",
  },
  {
    name: "Mini Escavadeira",
    img: require("./img/R80LC-7-2011.jpg"),
    description: "MARCA HYUNDAI, MODELO R80L-7, ANO 2011",
  },
  {
    name: "Escavadeira",
    img: require("./img/R210LC-7-2008.jpg"),
    description: "MARCA HYUNDAI, MODELO R210LC-7, ANO 2008",
  },
  
  {
    name: "Escavadeira",
    img: require("./img/320DFM-2012.jpg"),
    description: "MARCA CATERPILLAR, MODELO 320D FM, ANO 2012",
  },
  {
    name: "Caminhão",
    img: require("./img/112HS-1985.jpg"),
    description: "CAMINHÃO SCANIA 112HS, ANO/MODELO 1985",
  },
  {
    name: "Pá Carregadeira",
    img: require("./img/92HZ-2013.jpg"),
    description: "MARCA CATERPILLAR MODELO 924HZ, ANO 2013",
  },
  {
    name: "Motoniveladora",
    img: require("./img/140C-2008.jpg"),
    description: "MARCA DRESSER, MODELO 140C, ANO 1988",
  },
  {
    name: "Escavadeira",
    img: require("./img/R140LC-9S-2013.jpg"),
    description: "MARCA HYUNDAI, MODELO 140LC-9S, ANO 2013",
  },
  {
    name: "Pá Carregadeira",
    img: require("./img/W20-1977.jpg"),
    description: "MARCA CASE MODELO W20, ANO 1977",
  },
  {
    name: "Escavadeira",
    img: require("./img/EC140LCM-2014.jpg"),
    description: "MARCA VOLVO, MODELO EC140LCM, ANO 2014",
  },
  {
    name: "Retroescavadeira",
    img: require("./img/416F2-2019.jpg"),
    description: "MARCA CATERPILLAR, MODELO 416F2, ANO 2019",
  },
  {
    name: "Retroescavadeira",
    img: require("./img/580M-2010.png"),
    description: "MARCA CASE, MODELO 580M, ANO 2010",
  },
  {
    name: "Escavadeira",
    img: require("./img/R160LC-2017.jpg"),
    description: "MARCA HYUNDAI, MODELO R160LC, ANO 2017",
  },
  {
    name: "Mini Carregadeira",
    img: require("./img/DX60-2020.jpg"),
    description: "MARCA DOOSAN MODELO DX60, ANO 2020",
  },
  {
    name: "Pá Carregadeira",
    img: require("./img/CLG835H-2021.jpg"),
    description: "MARCA LIU GONG, MODELO CLG835H, ANO 2021",
  },
  {
    name: "Trator de Esteiras",
    img: require("./img/D51EX-22-2011.jpg"),
    description: "MARCA KOMATSU, MODELO D51EX-22, ANO 2011",
  },
];

export function SemiNewPage() {
  return (
    <>
      <h2>Máquinas Seminovas</h2>
      <br />
      <div className="row">
        {machines.map((m, i) => {
          return <MachineCard machine={m} key={i} />;
        })}
      </div>
    </>
  );
}
