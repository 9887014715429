import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { SemiNewPage } from "../pages/semiNew/SemiNewPage";
import { ContactPage } from "../pages/contact/ContactPage";
import { NewMachinesPage } from "../pages/newMachines/NewMachinesPage";
import { HomePage } from "../pages/home/HomePage";
import { MachinePage } from "../pages/newMachines/MachinePage";
import { ForkliftsPage } from "../pages/forklifts/ForkliftsPage";

export function DefaultRouter() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/novas" element={<NewMachinesPage />} />
      <Route path="/novas/:id" element={<MachinePage />} />
      <Route path="/seminovas" element={<SemiNewPage />} />
      <Route path="/empilhadeiras" element={<ForkliftsPage />} />
      <Route path="/contato" element={<ContactPage />} />

      {/* no match route */}
      {/*https://reactrouter.com/docs/en/v6/getting-started/tutorial#adding-a-no-match-route*/}
      <Route path="*" element={<Navigate replace to={"/"} />} />
    </Routes>
  );
}
