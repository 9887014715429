import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import { Machine } from "../pages/semiNew/SemiNewPage";

export function MachineCard({ machine }: { machine: Machine }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="col-lg-6 mb-3">
      <div className="card border-0 shadow-sm">
        <div
          className="card-body d-flex flex-column align-items-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            backgroundColor: isHovered ? "#E9ECEF" : "transparent",
          }}
        >
          <p
            className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
            style={{ paddingBottom: "1rem" }}
          >
            {machine.name}
          </p>
          <Zoom>
            <img
              src={machine.img}
              alt=""
              style={{
                width: "100%",
                borderRadius: "10px",
                minHeight: "385.5px",
              }}
            />
          </Zoom>
          <p style={{ paddingTop: "1rem" }}>{machine.description}</p>
        </div>
      </div>
    </div>
  );
}
