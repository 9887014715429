import React, { useState } from "react";
import { MACHINES, NewMachine } from "./machines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";

type PageState = "init" | "bulldozer" | "wheelLoader";

export function NewMachinesPage() {
  const [searchParams] = useSearchParams();
  const machineType = searchParams.get("type");

  const initialState: PageState =
    machineType === "bulldozer" || machineType === "wheelLoader"
      ? machineType
      : "init";

  const [pageState, setPageState] = useState<PageState>(initialState);

  const isBulldozerSelected = pageState === "bulldozer";
  const isWheelLoaderSelected = pageState === "wheelLoader";

  const bulldozers = MACHINES.filter((m) => m.type === "bulldozer");
  const wheelLoaders = MACHINES.filter((m) => m.type === "wheelLoader");

  if (pageState === "init")
    return (
      <>
        <h2>Máquinas Novas</h2>
        <div className="row">
          <MachineKindCard
            machine={{
              name: "Escavadeiras",
              description:
                "Desenvolvidas para se adaptarem a diversas operações, não iporta o quão intenso seja o trabalho.",
              img: require("./img/R220LC-9.png"),
            }}
            onClick={() => setPageState("bulldozer")}
          />
          <MachineKindCard
            machine={{
              name: "Pás Carregadeiras",
              description:
                "As Pás Carregadeiras Hyundai garantem eficácia em campo, devido a sua robustez e agilidade.",
              img: require("./img/HL757-9.png"),
            }}
            onClick={() => setPageState("wheelLoader")}
          />
        </div>
      </>
    );

  return (
    <>
      <h2>Máquinas Novas</h2>
      <div
        style={{
          padding: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <button
          className="btn btn-primary btn-lg"
          style={{ backgroundColor: "#ffc03a", borderColor: "#ffc03a" }}
          disabled={isBulldozerSelected}
          onClick={() => setPageState("bulldozer")}
        >
          Escavadeiras
        </button>
        <button
          className="btn btn-primary btn-lg"
          style={{ backgroundColor: "#ffc03a", borderColor: "#ffc03a" }}
          disabled={isWheelLoaderSelected}
          onClick={() => setPageState("wheelLoader")}
        >
          Pás Carregadeiras
        </button>
      </div>

      {isBulldozerSelected && (
        <>
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div
              className="card-body d-flex flex-column align-items-center"
              style={{ textAlign: "justify" }}
            >
              <p>
                As Escavadeiras Hyundai são projetadas para encarar as operações
                com máxima robustez, alta produtividade e excelente
                custo-benefício. Equipadas com componentes de qualidade
                reconhecida no mercado, elas apresentam eletrônica avançada,
                alcançam maior torque, por isso enviam respostas rápidas durante
                a execução dos trabalhos. As operações ganham mais eficiência e
                economia com as Escavadeiras Hyundai!
              </p>
            </div>
          </div>
          <div className="row">
            {bulldozers.map((m, i) => {
              return <NewMachineCard machine={m} key={i} />;
            })}
          </div>
        </>
      )}

      {!isBulldozerSelected && (
        <>
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div
              className="card-body d-flex flex-column align-items-center"
              style={{ textAlign: "justify" }}
            >
              <p>
                As Pás Carregadeiras Hyundai apresentam robustez e resistência
                para executar as operações com total desempenho. Preparadas para
                trabalhar com máxima eficiência nas mais diversas condições de
                solo, as Pás Carregadeiras Hyundai entregam produtividade e
                força de tração associados a recursos que geram excelente
                economia. Tudo para proporcionar a melhor relação
                custo-benefício e performance de alto impacto!
              </p>
            </div>
          </div>
          <div className="row">
            {wheelLoaders.map((m, i) => {
              return <NewMachineCard machine={m} key={i} />;
            })}
          </div>
        </>
      )}
    </>
  );
}

function NewMachineCard({ machine }: { machine: NewMachine }) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="col-lg-6 mb-3">
      <div className="card border-0 shadow-sm">
        <div
          className="card-body d-flex flex-column align-items-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => navigate(`/novas/${machine.id}`)}
          style={{
            backgroundColor: isHovered ? "#E9ECEF" : "transparent",
            cursor: "pointer",
          }}
        >
          <p
            className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
            style={{ paddingBottom: "1rem" }}
          >
            {machine.name}
          </p>
          <img
            src={machine.img}
            alt=""
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </div>
      </div>
    </div>
  );
}

function MachineKindCard({
  machine,
  onClick,
}: {
  machine: {
    name: string;
    img: string;
    description: string;
  };
  onClick: () => void;
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="col-lg-6 mb-3">
      <div className="card border-0 shadow-sm">
        <div
          className="card-body d-flex flex-column align-items-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
          style={{
            backgroundColor: isHovered ? "#E9ECEF" : "transparent",
            cursor: "pointer",
          }}
        >
          <p
            className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
            style={{ paddingBottom: "1rem" }}
          >
            {machine.name}
          </p>
          <img
            src={machine.img}
            alt=""
            style={{ width: "85%", borderRadius: "10px" }}
          />
          <p style={{ paddingTop: "1rem" }}>{machine.description}</p>
          <p
            className="pull-right"
            style={{ color: "#ffc03a", fontWeight: "bold" }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Ver mais
          </p>
        </div>
      </div>
    </div>
  );
}
