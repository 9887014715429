import React from "react";
import { MachineCard } from "../../components/MachineCard";

type Machine = {
  name: string;
  img: string;
  description: string;
};

const machines: Machine[] = [
  {
    name: "Empilhadeira",
    img: require("./img/25B7-AC.jpg"),
    description: "MARCA HYUNDAI, MODELO 25B7 AC, ELÉTRICA, RECOBACOR DE 4 TON",
  },
  {
    name: "Empilhadeira",
    img: require("./img/25L-7M.jpg"),
    description: "MARCA HYUNDAI, MODELO 25L-7M GLP",
  },
];

export function ForkliftsPage() {
  return (
    <>
      <h2>Empilhadeiras Totalmente Reformadas</h2>
      <br />
      <div className="row">
        {machines.map((m, i) => {
          return <MachineCard machine={m} key={i} />;
        })}
      </div>
    </>
  );
}
