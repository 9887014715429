import React from "react";
import Carousel from "react-bootstrap/Carousel";

export function HomePage() {
  return (
    <>
      <Carousel style={{ marginBottom: "1rem" }}>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100 h-80"
            src={require("./img/banner_usadas.png")}
            alt=""
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={require("./img/banner1.png")}
            alt=""
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={require("./img/banner2.png")}
            alt=""
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={require("./img/banner3.png")}
            alt=""
          />
        </Carousel.Item>
      </Carousel>

      <div className="row align-items-end">
        <div className="col-lg-6 d-flex flex-column">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <img
                src={require("./img/foto4.jpg")}
                alt=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex flex-column">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <p style={{ textAlign: "justify" }}>
                Representante da marca Hyundai, fabricante mundial de máquinas
                para a construção e movimentação de cargas com sede na Coréia do
                Sul e fábrica no Brasil na cidade de Itatiaia/RJ, a{" "}
                <strong>KUNZLER MAQUINAS LTDA</strong> busca bem atender a todos
                os clientes, reconhecendo suas necessidades, procurando sempre
                resolver as dificuldades de mercado para aquisição de
                equipamentos e mantê-los em pleno funcionamento, com peças
                originais e serviços personalizados.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-start">
        <div className="col-lg-7 d-flex flex-column justify-content-around">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <p
                className="text-center h4 fw-bold ml-3 ml-lg-4 mb-0"
                style={{ paddingBottom: "1rem" }}
              >
                Nossa História
              </p>
              <div style={{ textAlign: "justify" }}>
                <p>
                  Fundada em 2006, por Nelson Paulo Kunzler, com o objetivo
                  principal de revender máquinas de Construção, a{" "}
                  <strong>KUNZLER MAQUINAS LTDA</strong> atende os segmentos de
                  terraplenagem, mineração, saneamento, agricultura, locação,
                  órgãos públicos e florestal.
                </p>
                <p>
                  A experiência de seu sócio fundador, no ramo de máquinas que
                  vem desde 1978, foi determinante para o sucesso do
                  empreendimento, obtendo destacada liderança no fornecimento,
                  principalmente de escavadeiras hidráulicas no RS.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-flex flex-column justify-content-around">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <img
                src={require("./img/fachada.jpg")}
                alt=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-start">
        <div className="col-lg-5 d-flex flex-column justify-content-around">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <img
                src={require("./img/foto5.jpg")}
                alt=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 d-flex flex-column justify-content-around">
          <div
            className="card border-0 shadow-sm"
            style={{ marginBottom: "1rem" }}
          >
            <div className="card-body d-flex flex-column align-items-center">
              <div style={{ textAlign: "justify" }}>
                <p>
                  A <strong>KUNZLER</strong> busca incansavelmente bem atender a
                  todos os clientes, reconhecendo suas necessidades,
                  viabilizando a aquisição de equipamentos e a manutenção dos
                  mesmos.
                </p>
                <p>
                  Com uma equipe comercial obstinada e estratégica, todos os
                  esforços são realizados para que os contatos resultem em
                  vendas, gerando negócios sólidos através de uma parceria
                  concreta e duradoura.
                </p>
                <p>
                  A ética e o respeito aos clientes sempre foram princípios
                  essenciais para quem trabalha em nossa Empresa. Participando
                  do mercado com força, dedicação e seriedade, a{" "}
                  <strong>KUNZLER</strong> busca, acima de tudo, oferecer
                  serviços onde a qualidade seja mais um importante diferencial.
                </p>
                <p>
                  Instalações amplas e adequadas, em terreno de 16.000m² com
                  área para atendimento técnico, estocagem de peças de reposição
                  e localização privilegiada, próxima ao aeroporto e da BR116,
                  possibilitando fácil acesso à todas regiões do Estado, são
                  características que conferem segurança aos clientes para
                  escolherem a <strong>KUNZLER</strong> como parceira.
                </p>
                <p>
                  O atendimento de máquinas a campo, em qualquer município do RS
                  é realizado por técnicos treinados pela fábrica em veículos
                  equipados com ferramental apropriado e moderno para solução de
                  problemas mecânicos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
