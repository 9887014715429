import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRootClose } from "react-overlays";
import { faNavicon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const url = useLocation();
  const ref = useRef(null);
  const handleRootClose = () => setIsOpen(false);
  useRootClose(ref, handleRootClose, { disabled: !isOpen });

  const navbarItems: NavbarItemProps[] = [
    {
      title: "Início",
      pathName: "/",
    },
    {
      title: "Novas",
      pathName: "/novas",
    },
    {
      title: "Seminovas",
      pathName: "/seminovas",
    },
    {
      title: "Empilhadeiras",
      pathName: "/empilhadeiras",
    },
    {
      title: "Contato",
      pathName: "/contato",
    },
  ];

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      ref={ref}
      style={{
        backgroundColor: "#ffc03a",
        boxShadow: "0px 2px 5px 2px #E9ECEF",
      }}
    >
      <div className="container ">
        <Link to="/">
          <div
            className="navbar-brand d-flex justify-content-center"
            style={{
              width: "10rem",
              height: "5.5rem",
              alignItems: "center",
            }}
          >
            <img
              src={require("./img/logo.png")}
              height="100"
              alt="kunsler logo"
            />
          </div>
        </Link>

        <button
          className="navbar-toggler border-white"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon icon={faNavicon} color="white" size="lg" />
        </button>

        <div
          className={
            isOpen
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          }
        >
          <div className="navbar-nav ms-auto me-3 text-white d-flex justify-content-end">
            {navbarItems.map((item, index) => (
              <NavbarItemsRender
                item={item}
                currentPath={url.pathname}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}

type NavbarItemProps = {
  title: string;
  pathName: string;
};

function NavbarItemsRender({
  item,
  currentPath,
}: {
  item: NavbarItemProps;
  currentPath: string;
}) {
  return (
    <>
      <Link
        className={`nav-link ms-2 ms-lg-5  ${
          currentPath === item.pathName ? "active fw-bold" : ""
        }`}
        to={item.pathName}
        style={{ color: "#FAFAFA" }}
      >
        {item.title}
      </Link>
    </>
  );
}
